.login-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loginBox {
    width: 445px;
    background: var(--white);
    color: var(--dark-blue);
    padding: 35px 40px;
    border-radius: 4px;
    margin-bottom: 25px;
    text-align: left;
  }

  .incode-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .title {
    .text {
        font-size: 28px;
        text-transform: unset;
      }
  }

  .subtitle {
    color: var(--grey);
    margin: 0 0 1rem;
    .text {
      font-size: 16px;
      text-transform: unset;
    }
  }
}
