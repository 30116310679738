.copy-data {
  margin-bottom: 10px;

  .data-to-copy {
    display: flex;
    align-items: center;
    border: solid 1px var(--off-white);
    border-radius: 5px;
    background-color: var(--light-off-white);
    padding: 0 10px;

    span.generated {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 380px;
      text-transform: none;
    }

    .tooltip-wrapper {
      border-left: solid 1px var(--off-white);
    }
  }
}
