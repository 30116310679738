.custom-toast-container {
  width: 100% !important;
  top: 120px !important;
  padding: 0 !important;
}

.custom-toast-wrapper {
  color: var(--white) !important;
  text-align: center;
  background-color: #ff5c6f !important;

  &.success {
    background-color: #0cd5a2 !important;
  }
}
