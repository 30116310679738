.side-nav-wrp {
  width: 270px;
  min-width: 260px;
  background-color: var(--white);
  min-height: 100vh;
  position: relative;
  z-index: 400;
  border-right: 1px solid var(--tertiary-color);

  .active {
    background-color: var(--accent-color);
  }

  .close-nav {
    cursor: pointer;
    display: none;
  }
  .menuNav {
    cursor: pointer;
    display: none;
  }

  .logo {
    padding: 32px 32px 32px 24px;
  }

  .inner-container {
    position: fixed;
    height: 100vh;
    width: 270px;
    display: grid;
    grid-template-rows: max-content auto;
  }

  .container-links {
    width: 270px;
    display: grid;
    grid-template-rows: auto 100px;
    align-items: center;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
    min-height: 100%;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    &.incodeAdmin {
      grid-template-rows: auto 100px;
    }
  }

  .nav-links,
  .nav-support {
    .links {
      list-style: none;
      font-weight: normal;
      font-size: 15px;
      li {
        margin: 5px auto;
        min-width: 210px;
        a {
          padding: 0.5rem 0.8rem;
          border-radius: 8px;
          display: flex;
          align-items: center;
          -webkit-font-smoothing: antialiased;

          .text {
            font-size: 15px;
            font-weight: 400;
          }

          svg {
            height: 24px;
            margin-right: 6px;
          }
          &:hover {
            background-color: to-rgba(var(--secondary-color), 0.1);
          }
          &.active {
            color: var(--white);
            svg {
              filter: brightness(0) invert(1);
            }
            .right {
              filter: none;
            }

            .text {
              color: var(--white);
            }
          }
          .right {
            margin-left: auto;
          }
        }
      }
      .session-link {
        display: flex;
        align-items: center;
        a {
          margin-right: auto;
        }
        span {
          background-color: var(--white);
          color: var(--accent-color);
          border-radius: 12px;
          min-width: 1.44rem;
          height: 1.44rem;
          font-size: 0.75rem;
          text-align: center;
          padding: 4px 4px 0 3px;
        }
      }
    }
  }

  .nav-links {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-top: 1.25rem;
    height: 100%;
    width: 100%;

    .select-wrapper {
      width: 230px;
      margin-top: 32px;
    }
    .links {
      li {
        &:hover {
          a {
            background-color: to-rgba(var(--secondary-color), 0.1);
          }
        }
        .active,
        .active:hover {
          background-color: var(--accent-color);
        }
      }
    }
  }

  .logout {
    padding: 0.5rem 0.8rem;
    cursor: pointer;

    .text {
      color: var(--red-warning);
    }
  }

  @media (max-width: 1200px) {
    position: fixed;
    transform: translateX(-100%);
    width: 270px;
    z-index: 9;
    transition: all 0.3s ease;
    &.responsive {
      position: fixed;
      transform: translateX(0);
      width: 270px;
      z-index: 900;
      transition: all 0.3s ease;
    }

    .close-nav {
      display: block;
      margin-left: auto;
      padding-top: 35px;
      transition: all 0.3s ease;
      position: absolute;
      right: 10px;
      z-index: 1;
    }

    .menuNav {
      display: flex;
      align-items: center;
      position: absolute;
      padding: 45px;
      top: -10px;
      left: 270px;
      padding-left: 30px;
      padding-bottom: 0;
      transition: opacity 0.3s ease;
      &.hide {
        opacity: 0;
      }
      .menu-nav {
        padding-right: 10px;
      }
    }
  }
}
