/* colors */
:root {
  --black: #000000;
  --dark-text: #20263D;
  --dark-blue: #21273B;
  --pale-dark-blue: #3E3F42;
  --divider-grey: #2E2E33;
  --light-grey: #FBFBFD;
  --spinner-light-grey: #F3F3F3;
  --white: #FFFFFF;
  --light-off-white: #EAEDF3;
  --off-white: #D1D2D6;
  --grey: #9EA0A5;
  --grey2: #878794;
  --dark-grey: #6B6C6F;
  --dark-grey2: #3E3F42;
  --light-blue: #009EFF;
  --checked-blue: #1665D8;
  --blue: #2E98DA;
  --link-blue: #00B2FD;
  --lighter-blue: #3CB0F7;
  --brand-light-blue: #3585F1;
  --red: #EC5858;
  --red-warning: #E6492D;
  --red-error: #FF5C6F;
  --background-red-error: rgba(255, 92, 111, 0.1);
  --warning: #FBBA33;
  --orange-warning: #F6AB2F;
  --green: #36AD46;
  --spinner-green: #16D47B;
  --violet: #8F30EE;
  --lilac: #8A2DA4;
  --light-lilac: #FED3FF;
  --purple: #4C2DA4;
  --light-purple: #D3D7FF;
  --tertiary-color: #ececec;
  --accent-color: #007aff;
  --secondary-color: #60667c;
  --primary-color: #21273b;
  --layout-bg: #fbfbfd;

  --black-opaque: rgba(0, 0, 0, 0.9);
  --overlay-grey: rgba(33, 39, 59, 0.06);
  --grey-100: rgba(112, 111, 111, 1);
  --grey-200: rgba(216, 216, 216, 1);
  --grey-300: rgba(220, 220, 220, 1);
  --grey-400: rgba(240, 240, 240, 1);
  --grey-500: rgba(247, 247, 247, 1);
  --cyan: rgba(33, 160, 210, 1);
  --cyan-opaque: rgba(33, 160, 210, 0.9);
  --cyan-hover: rgba(119, 195, 225, 1);
  --signal-orange: rgba(243, 149, 0, 1);
}
