@import './global.scss';

#root {
  width: 100vw;
  height: 100vh;
}

body {
  scrollbar-color: var(--off-white) var(--white);
}
