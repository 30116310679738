@import '../../global';

.authentication-page {
  .table-wrapper {
    @extend %table-wrapper;
    margin-top: unset;
    height: 67vh;
  }

  .card_component__body {
    padding: 0;
  }

  ul {
    list-style: none;
  }

  table {
    @extend %table;
    border-left: none;
    border-right: none;
    thead {
      th {
        @extend %table-header;
        min-width: 120px;
        border-bottom: solid 1px var(--light-off-white);
        border-left: 0;
        border-right: solid 1px var(--light-off-white);
        border-top: 0;
        position: sticky;
        top: 0;
        left: 0;
        background: var(--light-grey);
        z-index: 10;
        white-space: nowrap;
      }
    }

    td {
      vertical-align: baseline;
      min-width: 120px;
      font-family: $CircularStd-Book;
      font-size: 13px;
      color: var(--primary-color);
      border-right: solid 1px var(--light-off-white);
      border-bottom: solid 1px var(--light-off-white);

      .action-cell {
        display: flex;
        justify-content: center;
        gap: 10px;
      }

      .auth-cell-block {
        display: block;
      }

      span {
        padding: 16px 18px;
      }

      li {
        padding: 8px 18px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--light-off-white);
        }
      }
      .auth-button-icon {
        margin: 10px 0;
      }
    }
  }

  .card__footer {
    width: 100%;
    display: flex;
    margin-top: 15px;
    padding: 1rem 1.88rem;
    flex-flow: row wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    border-top: solid 1px var(--light-off-white);
  }
}

.generated-data-modal {
  p {
    margin-bottom: 10px;
  }

  .data-to-copy {
    display: flex;
    align-items: center;
    border: solid 1px var(--off-white);
    border-radius: 5px;
    background-color: var(--light-off-white);
    padding: 0 10px;

    span.generated {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 380px;
      text-transform: none;
    }

    .tooltip-wrapper {
      border-left: solid 1px var(--off-white);
    }
  }
}
