%table-wrapper {
  max-width: 100%;
  z-index: 1;
  margin: auto;
  margin-left: unset;
  margin-top: 1.4rem;
  overflow: auto;
  height: 75vh;
}

%table {
  width: 100%;
  background-color: var(--white);
  border: solid 1px var(--light-off-white);
  border-top: none;
  position: relative;
  border-collapse: separate;
  border-spacing: 0;
}

%table-header {
  border-top: solid 1px var(--light-off-white);
  border-bottom: solid 1px var(--light-off-white);
  font-family: $CircularStd-Medium;
  text-align: left;
  padding: 0.75rem 1.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: var(--grey2);
}

%table-header-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background: var(--light-grey);
}

%table-cell {
  font-family: $CircularStd-Book;
  font-size: 0.94rem;
  color: var(--dark-grey);
  padding: 1.3rem 0;
  border-bottom: solid 1px var(--light-off-white);
}