
.app-wrapper {
  min-height: 100vh;
  display: flex;
  max-width: 100vw;
  margin: auto;
  background-color:  var(--layout-bg);
  color:  var(--black);
  .mainContent {
    width: calc(100% - 270px);
    padding: 30px;
  }

  @media screen and (max-width: 1200px) {
    .mainContent {
      width: 100%;
      padding-top: 60px;
    }
  }
}
